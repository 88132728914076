import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues, initialize, destroy, change } from 'redux-form';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { Radio, FormControlLabel, Typography, Box } from '@mui/material';
import ModalWindow from '../../../ModalWindow';
import { PrimaryButton, CancelButton } from '../../../../components/UIKit';
import {
  renderRadioGroup,
  Switcher,
  renderTextField,
  CustomFormFieldButton,
  CustomTextInput
} from '../../../../components/ReduxFormFields';
import { closeModalWindow } from '../../../ModalWindow/slice';
import i18n from '../../../../i18n';
import { Expander, Tooltip } from '../../../../components';
import { StepsEditFormBySeason } from './StepsEditFormBySeason';
import {
  parseFloatNumbersWithOneDigitAfterPoint,
  validateFloatWithThreeDecimal,
  required,
  getConnectedToProviderValidation
} from '../../../validation';
import {
  editTariffId as editTariffFormID,
  tariffTypes,
  PROVIDERS_LIST_ID,
  directMarketing
} from '../../constants';
import Select from '../../../DevicesTable/components/Select';

import DataList from '../../../DataList';
import { PROVIDERS_URL } from '../../../../api/apiUrls';
import { APP_ID, HELION_APP_ID, SOLAR_MANAGER_APP_ID } from '../../../../config';
import { useLazyStartOauth2ForProvidersQuery } from '../../services';

const renderLabel = (label) => (
  <Typography sx={{ color: 'secondary.main' }} variant="h4">
    {label}
  </Typography>
);

const formSelector = createSelector(
  getFormValues(editTariffFormID),
  (formValues) => formValues
);

const providersSelector = createSelector(
  (state) => state.dataList[PROVIDERS_LIST_ID]?.data,
  (providers) => providers || []
);

const EditTariffWindow = (props) => {
  const { reset, handleSubmit, unit, myRoleType, user, initialValues } = props;
  const dispatch = useDispatch();
  const formValues = useSelector(formSelector) || {};
  const modal = useSelector((state) => state.modals[editTariffFormID]);
  const { tariffType, provider, connectedTo, directMarketing: directMarketingFormValue } = formValues;
  const providers = useSelector(providersSelector);
  const selectedProvider = tariffType === tariffTypes.dynamic && providers.find((item) => item.value === provider);
  const isSelectedOauth2Provider = selectedProvider?.type === 'oauth2';
  const isSelectedMeteringCode = selectedProvider?.type === 'meteringCode';
  const isInitialOauth2Provider = tariffType === tariffTypes.dynamic
    && providers.find((item) => item.value === initialValues.provider)?.type === 'oauth2'
    && initialValues.provider === provider;

  const isHelionUser = user?.company?.name.includes('Helion');
  const isRenderDirectMarketingField = (APP_ID === HELION_APP_ID && !['end_user', 'sub_user'].includes(myRoleType)) || (APP_ID === SOLAR_MANAGER_APP_ID && ['root', 'solar_admin'].includes(myRoleType));
  const directMarketingOptions = directMarketing.map((option) => ({ label: i18n.t(option.label), value: option.value }));
  const handleOnClose = () => dispatch(closeModalWindow({ modalID: editTariffFormID }));

  const validateConnectedTo = useMemo(() => getConnectedToProviderValidation(initialValues.provider), [initialValues.provider]);

  const [startOauth2ForProviders, { isLoading: isStartOauth2ForProvidersLoading }] = useLazyStartOauth2ForProvidersQuery();

  const connectProvider = () => {
    startOauth2ForProviders({
      provider,
      modalId: editTariffFormID,
      uiRedirectUrl: window.location.href,
      userId: user._id
    });
  };

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  useEffect(() => {
    if (selectedProvider) {
      dispatch(change(editTariffFormID, 'providerType', selectedProvider.type));
    }
  }, [selectedProvider]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialValues || modal?.data?.formData) {
      dispatch(initialize(editTariffFormID, { ...initialValues, ...(modal?.data?.formData || {}) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, modal?.data?.formData]);

  useEffect(() => () => dispatch(destroy(editTariffFormID)), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DataList listID={PROVIDERS_LIST_ID} listURL={PROVIDERS_URL} headers={{ 'api-version': 3 }} />
      <ModalWindow modalID={editTariffFormID}>
        <div className="modal-header">
          <h5 className="modal-title">{i18n.t('tariffSettings')}</h5>
        </div>
        <div className="modal-body">
          <form
            id={editTariffFormID}
            onSubmit={handleSubmit}
            className="m-login__form m-form pop-up-form edit-tariff-form"
          >
            {isHelionUser && isRenderDirectMarketingField && (
              <Expander expanded>
                <Typography sx={{ mt: 2, color: 'secondary.dark' }} variant="body2">
                  {i18n.t('directMarketing')}
                </Typography>
                <Field
                  name="directMarketing"
                  component={Select}
                  className="m-input"
                  options={directMarketingOptions}
                  defaultValue={directMarketing}
                />
              </Expander>
            )}

            {isHelionUser && isRenderDirectMarketingField && directMarketingFormValue === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  my: 1,
                  mt: 3
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      color: 'secondary.main',
                      minWidth: 'max-content',
                      pr: 0.5
                    }}
                    variant="body2"
                  >
                    {`${i18n.t('exportPrice')}`}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'secondary.main',
                      minWidth: 'max-content'
                    }}
                    variant="body2"
                  >
                    {`(${unit})`}
                  </Typography>
                </Box>
                <Field
                  InputProps={{
                    disableUnderline: true,
                    sx: { backgroundColor: 'primary.dark', borderRadius: '4px', p: '4px 20px', maxWidth: '150px' }
                  }}
                  variant="standard"
                  sx={{ color: 'secondary', fontSize: '14px', flexBasis: '70%' }}
                  component={renderTextField}
                  name="exportStandardTariff"
                  parse={parseFloatNumbersWithOneDigitAfterPoint}
                  validate={[required, validateFloatWithThreeDecimal]}
                />
              </Box>
            )}
            <Typography sx={{ mt: 2, color: 'secondary.dark' }} variant="body2">
              {i18n.t('tariffType')}
            </Typography>
            <Field
              sx={{
                justifyContent: 'space-between',
                minWidth: 'max-content'
              }}
              row
              name="tariffType"
              component={renderRadioGroup}
            >
              <FormControlLabel
                value="single"
                control={<Radio disableRipple />}
                label={renderLabel(i18n.t('single'))}
              />
              <FormControlLabel
                value="double"
                control={<Radio disableRipple />}
                label={renderLabel(i18n.t('double'))}
              />
              <FormControlLabel
                value="dynamic"
                control={<Radio disableRipple />}
                label={renderLabel(i18n.t('dynamic'))}
              />
            </Field>

            <Expander expanded={tariffType === tariffTypes.single}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    my: 1
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        color: 'secondary.main',
                        minWidth: 'max-content',
                        pr: 0.5
                      }}
                      variant="body2"
                    >
                      {`${i18n.t('singleTariff')}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: 'secondary.main',
                        minWidth: 'max-content'
                      }}
                      variant="body2"
                    >
                      {`(${unit})`}
                    </Typography>
                  </Box>
                  <Field
                    InputProps={{
                      disableUnderline: true,
                      sx: { backgroundColor: 'primary.dark', borderRadius: '4px', p: '4px 20px', maxWidth: '150px' }
                    }}
                    variant="standard"
                    sx={{ color: 'secondary', fontSize: '14px', flexBasis: '70%' }}
                    component={renderTextField}
                    name="singleTariff"
                    parse={parseFloatNumbersWithOneDigitAfterPoint}
                    validate={
                      formValues.tariffType === tariffTypes.single
                        ? [required, validateFloatWithThreeDecimal]
                        : []
                    }
                  />
                </Box>
                <Typography sx={{ my: 2, color: 'secondary.dark' }} variant="body1">
                  {i18n.t('singleTariffDescription')}
                </Typography>
              </Box>
            </Expander>
            <Expander expanded={tariffType === tariffTypes.double}>
              <Typography
                sx={{ my: 2, textTransform: 'capitalize', color: 'secondary.dark' }}
                variant="body2"
              >
                {i18n.t('price')}
                {`: (${unit})`}
              </Typography>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', columnGap: '35px', mb: 1 }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    columnGap: '15px',
                    flexBasis: '50%'
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      color: 'secondary.lowTariff',
                      minWidth: 'max-content',
                      flexBasis: '30%'
                    }}
                    variant="body2"
                  >
                    {`${i18n.t('lowTariff')}`}
                  </Typography>
                  <Field
                    InputProps={{
                      disableUnderline: true,
                      sx: { backgroundColor: 'primary.dark', borderRadius: '4px', p: '4px 20px' }
                    }}
                    variant="standard"
                    sx={{ color: 'secondary', fontSize: '14px', flexBasis: '70%' }}
                    component={renderTextField}
                    name="lowTariff"
                    parse={parseFloatNumbersWithOneDigitAfterPoint}
                    validate={
                      formValues.tariffType === tariffTypes.double
                        ? [required, validateFloatWithThreeDecimal]
                        : []
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    columnGap: '15px',
                    flexBasis: '50%'
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      color: 'secondary.highTariff',
                      minWidth: 'max-content',
                      flexBasis: '30%'
                    }}
                    variant="body2"
                  >
                    {`${i18n.t('hightTariff')}`}
                  </Typography>
                  <Field
                    InputProps={{
                      disableUnderline: true,
                      sx: { backgroundColor: 'primary.dark', borderRadius: '4px', p: '4px 20px' }
                    }}
                    variant="standard"
                    sx={{ color: 'secondary', fontSize: '14px', flexBasis: '70%' }}
                    component={renderTextField}
                    name="highTariff"
                    parse={parseFloatNumbersWithOneDigitAfterPoint}
                    validate={
                      formValues.tariffType === tariffTypes.double
                        ? [required, validateFloatWithThreeDecimal]
                        : []
                    }
                  />
                </Box>
              </Box>
              <StepsEditFormBySeason formValues={formValues} field="commonSeasons" />
              <Box sx={{ display: 'flex', columnGap: 2, alignItems: 'center', my: 3 }}>
                <Field
                  name="isWinterTimeEnabled"
                  component={Switcher}
                  props={{
                    margin: 0,
                    width: 50,
                    height: 25,
                    onColor: '#CE181E',
                    className: 'switcher m-0'
                  }}
                />
                <Typography sx={{ color: 'secondary.main' }} variant="h4">
                  {`${i18n.t('winterTimeTariff')}`}
                  {' '}
                  <Tooltip title={i18n.t('winterTimeTariffTooltip')} placement="right" />
                </Typography>
              </Box>
              <Expander expanded={Boolean(formValues.isWinterTimeEnabled)}>
                <Typography sx={{ color: 'secondary.dark', mt: 2, mb: 1 }} variant="body1">
                  {`${i18n.t('wintertimeTariffDescription')}`}
                </Typography>
                <StepsEditFormBySeason formValues={formValues} field="winterSeason" />
              </Expander>
            </Expander>
            <Expander expanded={tariffType === tariffTypes.dynamic}>
              <Box sx={{ mt: 3 }}>
                <Field
                  name="provider"
                  component={Select}
                  showTooltip="selectProviderTooltip"
                  placeholder={`${i18n.t('selectProvider')}...`}
                  label={i18n.t('selectProvider')}
                  className="m-input"
                  options={providers}
                  validate={tariffType === tariffTypes.dynamic ? [required] : []}
                />

                {(isSelectedOauth2Provider || isInitialOauth2Provider) && (
                  <Field
                    name="connectedTo"
                    component={CustomFormFieldButton}
                    label={(isInitialOauth2Provider || connectedTo === provider) ? 'connected' : 'connect'}
                    onClick={connectProvider}
                    validate={[validateConnectedTo]}
                    disabled={isStartOauth2ForProvidersLoading}
                    isShowCheckmk={isInitialOauth2Provider || connectedTo === provider}
                  />
                )}

                {isSelectedMeteringCode && (
                  <div className="mt-4">
                    <Field
                      name="meteringCode"
                      showTooltip="meteringCodeTooltip"
                      component={CustomTextInput}
                      className="m-input w-100"
                      required
                      label="meteringCode"
                      validate={tariffType === tariffTypes.dynamic ? [required] : []}
                    />
                  </div>
                )}

                <Typography sx={{ my: 2, color: 'secondary.dark' }} variant="body1">
                  {i18n.t('dynamicPriceSubTitle')}
                </Typography>
              </Box>
            </Expander>
          </form>
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={handleOnClose} />
          <PrimaryButton form={editTariffFormID} isSubmit type="save" />
        </div>
      </ModalWindow>
    </>
  );
};

EditTariffWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  unit: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired
};

const form = reduxForm({
  form: editTariffFormID
})(EditTariffWindow);

export default form;
